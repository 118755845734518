import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { disconnect } from "process";
import toastAlert from "../script/ToastAlert";
import Web3 from "web3";
import { useDisconnect, useNetwork, useWalletClient } from "wagmi";
import { CreatUser } from "../actions/user";
// import { useDisconnect } from 'wagmi' 
import config from '../lib/config'
import { walletClientToSigner } from "./ethersconnect";

export default function NavbarInner() {
  const [address,setAddress]=useState()
  const [balance,setBalance]=useState()
  const [display,setDisplay]=useState(false)
  let decimalvalues = 1000000000000000000
  let chainId = config.NetworkId;

  const { disconnect, isSuccess, status } = useDisconnect()
  const { chain } = useNetwork()
  const { data: walletClient } = useWalletClient({ chainId })
  console.log(walletClient, 'walletClient');
  
  

  // const currectAddress=localStorage.getItem('address')
  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }
  useEffect(() => {
    loadScript();
    GetAccountDetails()
    connectAccount()
  }, [walletClient]);


  const connectAccount=async()=>{
   
      if (walletClient) {
        var { signer, transport } = walletClientToSigner(walletClient);
        console.log("transportsssssssssss: ", transport);
        var web3 = new Web3(transport);

        console.log("userdetails: ", {
          network: config.NetworkId,
          web3: web3,
          address: walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
          // userdetails: userdetails
        });
        console.log(
          config.NetworkId,
          chain.id,
          "config.NetworkIdconfig.NetworkId"
        );
        // var web3New = new Web3(window.ethereum);
        // var netId = await web3New.eth.net.getId();
        // console.log(netId, 'netIdnetIdnetId')
        console.log(chain.id, config.NetworkId, 'tttt');
        if (chain && (chain.id == config.NetworkId)) {
          if (localStorage.getItem('address') !== walletClient.account.address) {
            var balance = await web3.eth.getBalance(walletClient.account.address);
            const responce = await CreatUser(walletClient.account.address)
            console.log(responce, 'responce');
            localStorage.setItem("7thSeaLogin", "yes");
            localStorage.setItem("wallet_type", "metamask");
            const getAddr = await JSON.parse(localStorage.getItem("wagmi.store"))
            console.log(getAddr, 'fvsefsefgesgegvesg');
            localStorage.setItem("address", getAddr?.state?.data?.account);
            window.location.reload()
          } else {
            return ''
          }
        } else {
          var web3 = new Web3("https://rpc.sepolia.org");
          const accounts=await  window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{chainId:web3.utils.toHex(config.NetworkId)} ]
          })
             
          console.log('runner',accounts);
          const account = await walletClient.account.address
          if (localStorage.getItem('address') !== account) {
            var balance = await web3.eth.getBalance(walletClient.account.address);
            const responce = await CreatUser(walletClient.account.address)
            console.log(responce, 'responce');
            localStorage.setItem("7thSeaLogin", "yes");
            localStorage.setItem("wallet_type", "metamask");
            const getAddr = await JSON.parse(localStorage.getItem("wagmi.store"))
            localStorage.setItem("address", getAddr?.state?.data?.account);

            window.location.reload()
          } else {
            return ''
          }
        }

        console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
  

  //   if (localStorage.getItem('address') == walletClient.account.address) {
  //     return
  //   } else {
  //     localStorage.setItem("7thSeaLogin", "yes");
  //     localStorage.setItem("wallet_type", "metamask");
  //     localStorage.setItem("address", walletClient.account.address);
  //     const responce = CreatUser(walletClient.account.address)
  //          console.log(responce, 'responce');
  //     window.location.reload()
  //   }
  // }else{
  //   return ''
  // }
  }
}
  // const disconnect=async()=>{
  //   localStorage.clear()
  //   window.location.replace('/7thSea')
  // }
  const copytoClipBoard=(address)=>{
    navigator.clipboard.writeText(address)
    toastAlert('success','copied')
  }
  const GetAccountDetails = async () => {
    try {
      if (!window.ethereum) {
        alert("Please Add Metamask External");
      } else {
        if (localStorage.getItem('7thSeaLogin') === 'yes') {
          const web3 = new Web3(window.ethereum)
          if (typeof web3 === 'undefined') {
            alert("Please Add Metamask External");
          } else {
            console.log();
            const add = await localStorage.getItem('address')
            console.log(add, 'address');
            setAddress(add)
            let bal = await web3.eth.getBalance(add)
            bal = (parseFloat(bal) / decimalvalues.toFixed(2))
            console.log(bal,parseFloat(bal),bal.toFixed(2));
            setBalance(bal.toFixed(4))
            // toastAlert('success',`Account Address:${add},with Balance:${bal/1e18}`)
          }
        } else {

        }
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    if (isSuccess == true) {
      window.location.replace('/7thSea')
      setTimeout(() => {
        // window.location.reload(false)
      }, 500)
      localStorage.clear()
    }
  }, [isSuccess])

  const handleChange=()=>{
    setDisplay(!display)
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                <div className="dropdown me-3 header-profile" >
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                    onClick={()=>handleChange()}
                  >
                   {address}
                  </a>
                  <div
                  className={`${display ? "show" : ""
                } dropdown-menu walletdrop`}
                    aria-labelledby="dropdownMenuLink"
                  >
                    <div className="dropdown-header">
                      <h6 className="title mb-2">
                        <span>{address}</span>
                        <a style={{cursor:'pointer'}} onClick={()=>copytoClipBoard(address)} >
                          {/* <i className="bi bi-box-arrow-up-right" ></i> */}
                          <img
                                src={require("../assets/images/copy_icon.png")}
                                className="img-fluid"
                                alt="Copy"
                              />
                        </a>
                      </h6>
                      {/* <span>
                        <i className="far fa-envelope"></i>
                      </span> */}
                    </div>
                    <div className="dropdown-body">
                      <Link href="/profile">
                        <i className="bi bi-wallet2"></i> Balance - {balance} {config.Symbol}
                      </Link>
                      { /*<Link href="/kyc">
                        <i className="bi bi-graph-up"></i> Portfolio
                      </Link>
                      <Link href="/referral">
                        <i className="bi bi-megaphone"></i> Offers
                      </Link>
                      <Link href="/payment-methods">
                        <i className="bi bi-card-checklist"></i> Activity
                      </Link> */}
                      <Link href="/settings" onClick={()=>disconnect()}>
                        <i className="bi bi-box-arrow-left"></i> Disconnect
                      </Link>
                    </div>
                    {/* <div className="dropdown-footer">
                      <button className="">Disconnect</button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
