import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import connectTrustwallet, { metamaskConnection, TrustWalletConnection } from "../script/connectWallet";
import Web3 from "web3";
// import { config } from "process";
import { toast } from "react-toastify";
import toastAlert from "../script/ToastAlert";
import { useAccount, useConnect, useNetwork, useSwitchNetwork, useWalletClient } from "wagmi";

import browser from 'browser-detect';
import { walletClientToSigner } from "./ethersconnect";
import { CreatUser } from "../actions/user";
import config from '../lib/config'
import { isMobile } from "web3modal";
// import { log } from "console";

toast.configure()

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");
  const [Balance, setBalance] = useState()
  const [Token, setToken] = useState()
  const [address, setAddress] = useState()
  const [model, setModel] = useState(false)
  const [display, setDisplay] = useState(false)
  const buttonRef = useRef()
  const TestRef = useRef(null)
  let decimalvalues = 1000000000000000000
  const Symbol = config.Symbol
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()


  // const network = useSwitchNetwork({
  //   onMutate(args) {
  //     console.log("Mutate", args);
  //   },
  //   onError(error) {
  //     console.log("Error", error);
  //   },
  // });
  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      localStorage.clear()
      // dispatch(
      //   setWallet({
      //     network: config.NetworkId,
      //     web3: web3,
      //     address: "",
      //     provider: null,
      //     connect: "",
      //     isChange: "",
      //   })
      // );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    },
  });
  const { chain } = useNetwork()
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient(chainId)
  console.log(walletClient, 'walletClient');


  useEffect(() => {
    loadScript();
    // getLoader()

  }, []);


  useEffect(() => {
    setConnection()
    GetAccountDetails()
  }, [walletClient, chain?.network])


  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

 
  const disconnect = () => {
    localStorage.clear()
    window.location.reload('')
  }
 

  async function setConnection() {
    try {
      console.log("RRRRRRRRRRRRRRRRRRRR", walletClient, 'cccccccc', chain, 'walletClient');

      if (walletClient) {
        var { signer, transport } = walletClientToSigner(walletClient);
        console.log("transportsssssssssss: ", transport);
        var web3 = new Web3(transport);

        console.log("userdetails: ", {
          network: config.NetworkId,
          web3: web3,
          address: walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
          // userdetails: userdetails
        });
        console.log(
          config.NetworkId,
          chain.id,
          "config.NetworkIdconfig.NetworkId"
        );
        // var web3New = new Web3(window.ethereum);
        // var netId = await web3New.eth.net.getId();
        // console.log(netId, 'netIdnetIdnetId')
        console.log(chain.id, config.NetworkId, 'tttt');
        if (chain && (chain.id == config.NetworkId)) {
          if (localStorage.getItem('address') !== walletClient.account.address) {
            var balance = await web3.eth.getBalance(walletClient.account.address);
            const responce = await CreatUser(walletClient.account.address)
            console.log(responce, 'responce');
            localStorage.setItem("7thSeaLogin", "yes");
            localStorage.setItem("wallet_type", "metamask");
            const getAddr = await JSON.parse(localStorage.getItem("wagmi.store"))
            console.log(getAddr, 'fvsefsefgesgegvesg');
            localStorage.setItem("address", getAddr?.state?.data?.account);
            window.location.reload()
          } else {
            return ''
          }
        } else {
          var web3 = new Web3("https://rpc.sepolia.org");
          console.log(window);
          const accounts=await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{chainId:web3.utils.toHex(config.NetworkId)} ]
          })
             
          console.log('runner',accounts);
          const account = await walletClient.account.address
          if (localStorage.getItem('address') !== account) {
            var balance = await web3.eth.getBalance(walletClient.account.address);
            const responce = await CreatUser(walletClient.account.address)
            console.log(responce, 'responce');
            localStorage.setItem("7thSeaLogin", "yes");
            localStorage.setItem("wallet_type", "metamask");
            const getAddr = await JSON.parse(localStorage.getItem("wagmi.store"))
            localStorage.setItem("address", getAddr?.state?.data?.account);

            window.location.reload()
          } else {
            return ''
          }
        }

        console.log(signer, transport, "@@@@@@@@@@@@@@@@@@@1");
      }
    } catch (error) {
      console.log(error);
    }
  }


  async function connectfunction(connector) {

    try {
      let check = isMobile();
      // alert('1')
      if (
        check &&
        !window.ethereum && connector &&
        connector.connector.id != "walletConnect"
      ) {
      //  alert('2')
        await connectMetamaskMobile();
       
        return;
      }
      if (window.ethereum) {
       
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          const accounts=await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{chainId:web3.utils.toHex(config.NetworkId)} ]
          })
        }
      }


      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);
      }, 1000);
    } catch (err) { 
      
    }
  }

 

  const handleChange=()=>{
    console.log(display);
    setDisplay(!display)
  }
  const GetAccountDetails = async () => {
    try {
      if (!window.ethereum) {
        alert("Please Add Metamask External");
      } else {
        if (localStorage.getItem('7thSeaLogin') === 'yes') {
          const web3 = new Web3(window.ethereum)
          if (typeof web3 === 'undefined') {
            alert("Please Add Metamask External");
          } else {
            console.log();
            const add = await localStorage.getItem('address')
            console.log(add, 'address');
            setAddress(add)
            let bal = await web3.eth.getBalance(add)

            bal = (parseFloat(bal) / decimalvalues.toFixed(2))
            console.log(bal, parseFloat(bal), bal.toFixed(2));
            setBalance(bal.toFixed(4))
            // toastAlert('success',`Account Address:${add},with Balance:${bal/1e18}`)
          }
        } else {

        }
      }
    } catch (error) {

    }
  }
  

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl+'/7thSea';
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

 

  const copytoClipBoard = async (address) => {
    await navigator.clipboard.writeText(address)
    //  console.log(data,'data')
    toastAlert('success', 'Copied')
  }
  console.log(connectors, error, 'CCCCCCCCCCCCCCCCCCCCCCCCC');
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right" >
                {/* After Login Menus */}

                {/* <button className="primary_btn">Connect Wallet</button> */}

                {
                  // localStorage.getItem('wallet_type') && (localStorage.getItem('wallet_type') === 'metamask' || localStorage.getItem('wallet_type') === 'trustwallet') 
                  localStorage.getItem('address') ? <>
                    <div className="dropdown me-3 header-profile" >
                      <a
                        className="primary_btn dropdown-toggle address_btn"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        onClick={handleChange} 
                      >
                        {localStorage.getItem('address')}
                      </a>

                      <div
                        className={`${display ?"show":""
                          } dropdown-menu walletdrop`}
                        // className="dropdown-menu walletdrop "
                        aria-labelledby="dropdownMenuLink"
                        
                      >
                        <div className="dropdown-header">
                          <h6 className="title mb-2" >
                            <span >{localStorage.getItem('address')}</span>
                            <a target="_blank" style={{ cursor: 'pointer' }} onClick={() => copytoClipBoard(localStorage.getItem('address'))}>
                              <img
                                src={require("../assets/images/copy_icon.png")}
                                className="img-fluid"
                                alt="Copy"
                              />
                            </a>
                          </h6>
                        </div>
                        <div className="dropdown-body">
                          <Link href="/">
                            <i className="bi bi-wallet2"></i> Balance - {Balance} {Symbol}
                          </Link>
                          {/* <Link href="/kyc">
                            <i className="bi bi-graph-up"></i> Portfolio
                          </Link>
                          <Link href="/referral">
                            <i className="bi bi-megaphone"></i> Offers
                          </Link>
                          <Link href="/payment-methods">
                            <i className="bi bi-card-checklist"></i> Activity
                          </Link> */}
                          {/* <Link href="/settings" onClick={() => disconnect()}>
                            <i className="bi bi-box-arrow-left" ></i> Disconnect
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <button className="primary_btn" onClick={() => disconnect()}>Disconnect</button>
                  </> : <div> <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"

                  >
                    Connect Wallet
                  </button></div>
                }


              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}

      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="false"

      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={buttonRef}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  
                    {connectors && connectors.length > 0 && connectors.map((connector) => (
                      <>


                        {
                          connector && connector.id == 'metaMask' ?
                          <a target="_blank" className="mb-3 d-block">
                            <button type="button" className="connect_wallet_button" 
                              key={connector.id}
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/metamask.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                                alt="metamask"
                              />{" "}
                              <span>MetaMask</span>
                               
                            </button>
                            </a> : ""
                        }

                        {
                          connector && connector.id == 'walletConnect' ?
                          <a target="_blank" className="mb-3 d-block">
                            <button type="button" className="connect_wallet_button"

                              key={connector.id}
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                                alt="TrustWallet"
                              />{" "}
                              <span>WalletConnect</span>
                            </button> 
                            </a>:
                            ''



                        }
                      </>

                    ))}

                    {error && <div>{error.message}</div>}                

                  {/* <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={() => walletConnection('metamask')}>
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                        alt="metamask"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={() => walletConnection('TrustWallet')}>
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                        alt="TrustWallet"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
