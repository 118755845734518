import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import Home from './pages/home.js';
import CollectionsDetails from './pages/collection-details.js';
import { ToastContainer } from "react-toastify";

import { WagmiConfig, createConfig, configureChains, mainnet, sepolia } from 'wagmi'
import { bsc, bscTestnet, polygon, polygonMumbai } from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
// import '@walletconnect/ethereum-provider/dist/index.es.js'
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/collection-details/:id", element: <CollectionsDetails /> },
  ]);
  return routes;
};
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet,sepolia],
  [publicProvider()],
)
const config = createConfig({

  autoConnect: true,
  connectors: [


    new MetaMaskConnector({ chains ,
      shimDisconnect: true,
    }),
    
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})
const AppWrapper = () => {
  return (
    <div>

      <ToastContainer />
    <Router basename="/7thSea">
    <WagmiConfig config={config}>
      <App />
      </WagmiConfig>
    </Router>
    </div>
  );
};

export default AppWrapper;