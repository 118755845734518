
import axios from "axios";
import React from "react";
import config from '../lib/config'
export const getAllCollectionList = async (data,pagenumber,pagelimit) => {
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/collectionlist`,
            method: 'get',
            params: { search: data ,page:pagenumber,limit:pagelimit}
        })
        console.log(responce,'responce');
        if (responce?.data?.result?.length > 0) {
           
            return responce.data
        } else {
            return 'No Data Found'
        }
    } catch (error) {
        console.log(error);
    }
}
export const CreatUser=async(data)=>{
    console.log(data,'data');
    try {
        
        const result=await axios({
            url:`${config.Back_URL}api/routesUser/createuser`,
            method:'post',
            data:{data}
        })
        return result.data
    } catch (error) {
        console.log(error);
    }
    
}
export const searchCollections = async (data) => {
    console.log(data);
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/searchcollection`,
            method: 'get',
            params: { search: data }
        })
        // console.log(responce.data.result.length);
        if (responce.data.result.length > 0) {
            return responce.data.result
        }
    } catch (error) {
        console.log(error);
    }
}

export const FilterCollectionList = async (value) => {
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/filtercollection`,
            method: 'post',
            data: { value }
        })
    } catch (error) {
        console.log(error);
    }
}

export const getCollection = async (data) => {
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/collection/${data}`,
            method: 'get',

        })
        if (responce) {
            console.log(responce.data,'marketmarket');
            const result={
                collection:responce.data.result,
                market:responce.data.market
            }
            return result
        }
    } catch (error) {
        console.log(error);
    }
}

export const getFaqCollection = async () => {
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/faqcollection`,
            method: 'get'
        })
        console.log(responce.data.result);
        return responce.data.result
    } catch (error) {
        console.log(error);
    }
}

export const nftMint = async (value) => {
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/mintNft`,
            method: 'post',
            data: value
        })
        console.log(responce);
        return responce.data
    } catch (error) {
        console.log(error);
    }
}

export const getTokenData=async(data)=>{
    console.log(data);
    try {
        const responce = await axios({
            url: `${config.Back_URL}api/routesUser/getusertoken`,
            method: 'post',
            data: data
        })
        console.log(responce,'responccccccccccccccccc');
        return responce.data
    } catch (error) {
        console.log(error);
    }
}

export const ClaimedShare=async(data)=>{
    console.log(data)
    try {
        const responce=await axios({
            url:`${config.Back_URL}api/routesUser/claimedshare`,
            method:'post',
            data:data
        })
        console.log(responce.data);
        return responce.data
    } catch (error) {

        console.log(error);
    }
}

export const getClaimbHistory=async(data)=>{
    console.log(data)
    try {
        const responce=await axios({
            url:`${config.Back_URL}api/routesUser/getclaimhistory`,
            method:'post',
            data:data
        })
        console.log(responce.data);
        return responce.data
    } catch (error) {
        console.log(error);
    }
}

export const UpdateToken=async(data)=>{
    console.log(data);
    try {
        const responce=await axios({
            url:`${config.Back_URL}api/routesUser/updateToken`,
            method:'post',
            data:data
        })
        console.log(responce.data);
        return responce.data
    } catch (error) {
        console.log(error);
    }
}