import React from "react";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="footer_panel_right">
            <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                <i className="fab fa-discord"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer_panel_left">
            <ul className="footer_menu_links">
              <li><a href="#">Home</a></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
            <p>
              Copy right © {new Date().getFullYear()} <a href="#">7th Sea</a>,
              All rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
