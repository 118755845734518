import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getClaimbHistory, getCollection, getTokenData } from "../actions/user.js";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import { ClaimbaleShares, MintNft } from "../script/mintnft.js";
import { ReadUserContract } from "../script/readusercontract.js";
import toastAlert from "../script/ToastAlert.js";
import Web3 from "web3";
import details from '../lib/network.js'
import { contractABI } from "../contract/contractABI.js";
import { toast } from "react-toastify";
import axios from "axios";
import { Converter } from "../script/converter.js";
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { URL } from "url";
import ReactLoading from 'react-loading'
// import { log } from "console";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function CollectionsDetails(props) {
  let decimalvalues = 1000000000000000000
  const [collection, setCollectionList] = useState({})
  const [TotalMint, setTotalMint] = useState('')
  const [UserMint, setUserMint] = useState('')
  const [UserHold, setUserHold] = useState('')
  const [value, setValue] = useState()
  const [whitelist, setWhileList] = useState(false)
  const [freeMint, setFreeMint] = useState(false)
  const currentAddress = localStorage.getItem('address')
  const [CurrentETHbalance, setCurrentETHbalance] = useState()
  const [baseURI, setBaseURi] = useState()
  const [TokenJson, setTokenJson] = useState([])
  const [totalSupply, setTotalSupply] = useState()
  const [CheckShare, setCheckShare] = useState([])
  const [WhiteListAddr, setWhiteListAddr] = useState(false)
  const [tokenId, setTokenId] = useState([])
  const [TotalShareValue, setTotalShareValue] = useState()
  const [marketlist, setMarketList] = useState([])
  const [mintloader, setMintLoader] = useState(false)
  const [loader, setLoader] = useState(undefined)
  const [AllShareloader, setAllShareLoader] = useState(false)
  const [address, setAddress] = useState()
  const [balance, setBalance] = useState()
  const [ClaimbedHistory, setClaimbedHistory] = useState([])
  const [singleShare, setSingleShare] = useState()
  const [PageLoader, setPageLoader] = useState(true)
  // const [tokenobject,setTokenObject]=useState([])
  console.log(TokenJson, "TokenJsonTokenJson");

  // const []
  const params = useParams()
  console.log(params);
  useEffect(() => {
    GetLoader()
    GetAccountDetails()
  }, [])


  const GetLoader = async () => {
    const { id } = params
    const result = await getCollection(id)
    console.log(result, 'resultresutl');
    await setCollectionList(result.collection)
    var uniq = await [...new Set()];
    console.log(uniq, 'uniq', result.market);
    await setMarketList(result.market)
    const payload = {
      ContractAddress: result.collection.ContractAddress,
      userAddress: currentAddress
    }
    const token = await getTokenData(payload)
    console.log(token, 'tokenid');
    setTokenId(token.data)
    setClaimbedHistory(token.result)

    // const result1 =await getClaimbHistory(payload)
    // console.log(result1,'Sakthi');
    await ReadUserContract(result.collection.ContractAddress, setTotalMint, setUserHold, setUserMint, setWhileList, setWhiteListAddr, setFreeMint, setCurrentETHbalance, setBaseURi, setTokenJson, setTotalSupply, setCheckShare, token.data, setTotalShareValue, setSingleShare)


  }
  //  console.log(UserHold,UserMint,TotalMint);
  console.log(collection, 'coll');
  // var tokenobject=0
  // if(tokenId.length>1){
  // const tokenob=tokenId.splice(0,tokenId.length);
  // console.log(tokenob,'tokenobject');
  //   tokenobject=tokenob
  // }
  // console.log(tokenobject,"tokenobject");

  setTimeout(() => {
    setPageLoader(false)
  }, 10000);

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{1,9})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{1,9})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };
  // const updateWhiteList = async () => {
  //   try {
  //     if (localStorage.getItem('wallet_type') && localStorage.getItem('wallet_type') == 'metamask') {

  //       const web3 = new Web3(details[0].rpcUrls)
  //       const contract = await new web3.eth.Contract(contractABI, collection.ContractAddress)
  //       const statusValue = 1
  //       console.log(contract, 'contracAdd', currentAddress, statusValue);
  //       const whitelisted = await contract.methods.updateWhitelistAddress([currentAddress], statusValue.toString()).send({ from: currentAddress })
  //       console.log(whitelisted, 'white');
  //     } else {
  //       //trust wallet
  //     }
  //   } catch (err) {
  //     console.log(err, 'erererre');
  //   }

  // }

  const sharelen = []
  for (let i = 0; i < TokenJson.length; i++) {
    if (TokenJson[i].shareValue != 0) {
      sharelen.push(TokenJson[i])
    }
  }
  console.log(TokenJson, sharelen, 'TokenJson================================');

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
      "0." +
      "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
      lead +
      decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }
  console.log(sharelen);
  const ClaimShares = async (tokenId, index, shareValue) => {
    // alert(index)
    if (shareValue > 0) {

      if (index != undefined) {

        setLoader(index)
      } else {

        setAllShareLoader(true)
      }
      console.log(tokenId);


      await ClaimbaleShares(tokenId, collection.ContractAddress, setLoader, setAllShareLoader)
    } else {
      toast.error('No share to claim')
    }
  }
  const GetAccountDetails = async () => {
    try {
      if (!window.ethereum) {
        alert("Please Add Metamask External");
      } else {
        if (localStorage.getItem('7thSeaLogin') === 'yes') {
          const web3 = new Web3(window.ethereum)
          if (typeof web3 === 'undefined') {
            toast.error("Please add metamask external");
          } else {
            console.log();
            const Account = await web3.eth.getAccounts()
            // const add = await localStorage.getItem('address')
            console.log(Account, 'address');
            localStorage.setItem('address', Account[0])
            setAddress(Account[0])
            let bal = await web3.eth.getBalance(Account[0])
            bal = (parseFloat(bal) / decimalvalues.toFixed(2))
            console.log(bal, parseFloat(bal), bal.toFixed(2));
            setBalance(bal.toFixed(4))
            // toastAlert('success',`Account Address:${add},with Balance:${bal/1e18}`)
          }
        } else {

        }
      }
    } catch (error) {

    }
  }
  const Converter = (address) => {
    { console.log(address); }

    if (address != undefined) {

      return `${address.slice(0, 9)}....${address.slice(address.length - 10)}`
    } else {
      return
    }

  }

  const SubmitHandler = async () => {
    try {
      console.log(typeof value,'ValueValueValueValue');
      if(value==undefined||value==''){
        toast.error('Mint quantity required ')
        return false
      }
      if (Number(value) <= 0) {
        toast.error('Mint quantity must be greater then 0 ')
        return false
      } else {
        if (!/^[0-9]+$/.test(value)) {
          toast.error('Mint quantity must not be decimal ')
        } else {
          if (whitelist) { //check if contract is white list 
            if (!WhiteListAddr) { // check is address is whitelist
              return toastAlert('error', 'Not whitelisted')
            }
          }

          if (collection.mintopen) { //check if mint open
            if (!collection.mintfinished) { //check if mint finished
              if (balance >= Number(value) * collection.nftprice) {
                if ((Number(UserHold) + Number(value)) <= Number(collection.maxholdingperaddress)) {  // check if 2* userhold  value is <= maxhold per add
                  if (Number(collection.totalsupply) >= (2 * Number(value)) + Number(TotalMint)) { // check if  total supply is >= 2* total mint + value
                    console.log((Number(UserMint) + Number(value)) <= Number(collection.maxmintperaddress));
                    if (Number(UserMint) + Number(value) <= Number(collection.maxmintperaddress)) { // check if usermint + 2* value <= maxmint per address
                      await MintNft(value, collection.ContractAddress, collection.nftprice, setMintLoader, TotalMint, collection.totalsupply)
                    } else {
                      return toastAlert('error', 'Maximum minting per address exceeds')
                    }
                  } else {
                    return toastAlert('error', 'User mint exceeds total supply')
                  }
                } else {
                  return toastAlert('error', 'Maximum holding per address is exceeds')
                }
              } else {
                return toastAlert('error', 'Insufficient balance in wallet')
              }
              console.log((Number(UserHold) + Number(value)) <= Number(collection.maxholdingperaddress));

            } else {
              return toastAlert('error', 'Minting is finished')
            }
          } else {
            return toastAlert('error', 'Mint is closed')
          }
        }
      }

    }


    catch (error) {
      console.log(error);
    }

  }



  // const getTokenData = async (ContractAddress) => {
  //   if (
  //     localStorage.getItem("wallet_type") &&
  //     localStorage.getItem("wallet_type") === "metamask"
  //   ) {
  //     if (!window && !window.ethereum) {
  //       return alert("please install metamask");
  //     } else {
  //       const wallet_type = await localStorage.getItem('network')
  //       console.log(wallet_type);
  //       const selectedNetwork = await details.find((list) => (String(list.name) === String(wallet_type)) ? list : '')
  //       console.log(selectedNetwork, 'selectedNetwork');
  //       const web3 = new Web3(selectedNetwork.rpcUrls);

  //       let currentAddress = localStorage.getItem("address");
  //       const contract = new web3.eth.Contract(contractABI, ContractAddress);
  //       console.log(contract, "contarctllll");

  //       const Supply = await contract.methods.totalSupply().call()
  //       .then(async(value)=>{
  //        await setTotalSupply(value)
  //       }).catch((err)=>{
  //         console.log(err);
  //       })

  //       let TokenArray = []

  //       for (var i = 0; i < totalSupply; i++) {
  //         const Token = await contract.methods.tokenURI(i).call()
  //           .then(async (value) => {

  //             const result = await axios({ url: `${value}`, method: 'get' })
  //             console.log(result.data, 'rrrrrrrrrrrrrrrrrrrrrrrrr');

  //             const text = JSON.parse(JSON.stringify(result.data))
  //             console.log(text, 'rrrrrrrrrrrrrrrrrrrrrrrrr');
  //             await TokenArray.push(text)
  //           }).catch(err => {
  //             console.log(err, 'err');
  //           })
  //         await setTokenJson(TokenArray)


  //       }

  //     }
  //   } else {
  //     console.log('errror');
  //   }
  // }
  const copy = (address) => {
    navigator.clipboard.writeText(address)
    toastAlert('success', 'Copied')
  }


  const changeHandler = (e) => {
    const val = e.target.value
    // if (/[[0-9]+]/.test(val)) {

    //   return false

    // } else {
    console.log(val);
    setValue(val)
    // }
  }
  console.log(TokenJson.length, totalSupply, 'TokenJsonlength')

  return (
    <div>
      <div>

        {
          PageLoader && <ReactLoading type={"spin"} color={"#000000"} className="loading" />
        }
        <ScrollToTopOnMount />
        <NavbarInner props={collection} />
        <div className="inner_wrapper">
          <div className="collection_banner" style={{ backgroundImage: `URL(${collection.bannerurl})`, backgroundRepeat: "no-repeat", backgroundPosition: 'top center' }} >
            <div className="container">
              <div className="dashboard_panel">
                <div className="row">
                  <div className="col-md-12">
                    <div className="collection_profile_wrap">
                      <img
                        src={`${collection.logourl}`}
                        className="img-fluid"
                        alt="Collection Profile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="collections_details_wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="collection_info">
                    <p>
                      MetaLine is a large-scale Web3-based multi-connect game for
                      nautical management, adventure and strategy. MetaLine has
                      six system modules, including a production and trading
                      system, an upgrading system, a port system, a combat system,
                      a NFT system, and an ecosystem. Obtain rich game play and
                      build your sea empire through cooperation and association
                      between different mission systems. With the gradual release
                      of missions, the built-in port system also opens the door
                      for more users to enter the meta-universe. Combined with the
                      combat system, players will get the best game experience.
                    </p>
                    <h2>MetaLine-Mystery Box</h2>
                    <div className="input_grp">
                      <input
                        className="form-control"
                        defaultValue={collection.ContractAddress}
                        readOnly
                      ></input>
                      <span className="copy_btn" onClick={() => copy(collection.ContractAddress)}>
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="img-fluid"
                          alt="Copy"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="collection_details_grid">
                    <div>
                      <h3>{collection.totalsupply}</h3>
                      <p>Total Supply</p>
                    </div>
                    <div>
                      <h3>{TotalMint}</h3>
                      <p>Total Minted</p>
                    </div>
                    <div>
                      {
                        collection.mintopen ? <h3 className="text_green">Open</h3> : <h3 className="text_red">Close</h3>
                      }
                      <p>Mint Status</p>
                    </div>
                    <div>
                      <h3>{UserMint}</h3>
                      <p>User Minted</p>
                    </div>
                    <div>
                      <h3>{UserHold}</h3>
                      <p>User Hold</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="collection_mint_box">
                    <h2>MetaLine-Mystery Box</h2>
                    <p>Enter the amount of NFTs you would like to Mint</p>
                    <div className="collection_mint_box_details">
                      <div>
                        <label>Mint Price</label>
                        <span>:</span>
                        {
                          collection.nftprice>0?
                          <label>{convert(collection.nftprice)} ETH</label>:
                          <label>0 ETH</label>
                        }
                      </div>
                      <div>
                        <label>Max Mint Per Address</label>
                        <span>:</span>
                     
                          <label>{collection.maxmintperaddress}</label>
                         
                  
                      </div>
                      <div>
                        <label>Max Hold Per Address</label>
                        <span>:</span>
                        <label>{collection.maxholdingperaddress}</label>
                      </div>
                    </div>
                    <input className="form-control" type="text" onInput={validPositive}
                      defaultValue=""  onChange={changeHandler}
                      onKeyDown={(evt) => {
                        ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                      }
                      }
                    />
                    <div className="text-center">
                      <button className="primary_btn" disabled={mintloader}
                        onClick={() => SubmitHandler()}>{mintloader ? <i className="fa fa-refresh fa-spin" style={{ fontSize: "24px" }}></i> : ''}Mint Now</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="collection_info_table">
                    <h1>
                      Collection's Balance = <span>{CurrentETHbalance>0?convert(CurrentETHbalance / 1e18):0} ETH</span>
                    </h1>
                    {
                      tokenId && tokenId.length > 0 ? <div className="claimable_share mb-4">
                        <h2>
                          Your Claimable Share = <span>{TotalShareValue>0? convert(TotalShareValue/ 1e18):0} ETH</span>
                        </h2>

                        {
                          (sharelen && sharelen.length <= 1) ? <button className="primary_btn" disabled={true} >

                            {AllShareloader ? <i class="fa fa-refresh fa-spin" style={{ fontSize: "24px" }}></i> : ''} Claim All Your Share
                          </button> : <button className="primary_btn" disabled={loader} onClick={() => { ClaimShares(tokenId, undefined, TotalShareValue) }}>

                            {AllShareloader ? <i class="fa fa-refresh fa-spin" style={{ fontSize: "24px" }}></i> : ''} Claim All Your Share
                          </button>
                        }
                        {/* <button className="primary_btn" disabled={loader} onClick={() => { ClaimShares(tokenId, undefined, TotalShareValue) }}>

                        {AllShareloader ? <i class="fa fa-refresh fa-spin" style={{ fontSize: "24px" }}></i> : ''} Claim All Your Share
                      </button> */}
                      </div> : ''
                    }
                    {
                      marketlist && marketlist.length > 0 ? <div>
                        <h2 className="inner_heading">Approved Marketplaces</h2>
                        <div className="approved_market_div">
                          <div className="table-responsive">
                            <table className="approved_market_table table">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Marketplace Name</th>
                                  <th>Website Address</th>
                                  <th>Contract Address</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  marketlist && marketlist.length > 0 && marketlist.map((list, index) => {
                                    return (
                                      <tr key={list._id}>
                                        <td>{index + 1}</td>
                                        <td>{list.marketname}</td>
                                        <td>
                                          <a href={list.websiteURL} target="_blank">
                                            {list.websiteURL}
                                          </a>
                                        </td>
                                        <td>
                                          <a href="#" target="_blank">
                                            {Converter(list.marketContractAddress)}
                                          </a>
                                          <img
                                            src={require("../assets/images/copy_icon.png")}
                                            className="img-fluid"
                                            alt="Copy"
                                            onClick={() => copy(list.marketContractAddress)}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  }
                                  )
                                }
                                {/* <tr>
                    <td>2</td>
                    <td>SeaGold</td>
                    <td>
                      <a href="#" target="_blank">
                        http://www.seagold.io/
                      </a>
                    </td>
                    <td>
                      <a href="#" target="_blank">
                        5xahpo25abg.....638495
                      </a>
                      <img
                        src={require("../assets/images/copy_icon.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Binance</td>
                    <td>
                      <a href="#" target="_blank">
                        https://www.binance.com/en/nft/home
                      </a>
                    </td>
                    <td>
                      <a href="#" target="_blank">
                        5xahpo25abg.....638495
                      </a>
                      <img
                        src={require("../assets/images/copy_icon.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>NFT</td>
                    <td>
                      <a href="#" target="_blank">
                        https://www.nft.com/
                      </a>
                    </td>
                    <td>
                      <a href="#" target="_blank">
                        5xahpo25abg.....638495
                      </a>
                      <img
                        src={require("../assets/images/copy_icon.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Rariable</td>
                    <td>
                      <a href="#" target="_blank">
                        https://rarible.com/
                      </a>
                    </td>
                    <td>
                      <a href="#" target="_blank">
                        5xahpo25abg.....638495
                      </a>
                      <img
                        src={require("../assets/images/copy_icon.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </td>
                  </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> : <></>
                    }



                  </div>

                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="owned_nft_wrap">

                    {

                      TokenJson.length > 0 && <h2 className="inner_heading">Your NFTs</h2>
                    }
                    {TokenJson && totalSupply &&
                      TokenJson.map((list, index) => (
                        <>
                          <div className="owned_nft_list">

                            {/* <div className="form-check">
                            <input
                              className="form-check-input form-checkbox-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                          </div> */}
                            <div className="nft_img_wrap">


                              <img
                                src={`${baseURI}/${list.image}`}
                                className="img-fluid"
                                alt="Copy"
                              />
                            </div>
                            <div className="nft_description">
                              <h2>Monster giant octopus sea</h2>
                              <h3>Token ID : {list.name}</h3>
                              <div className="nft_details">
                                {
                                  list.attributes.map((value) =>
                                  (
                                    <div >
                                      <label>{value.trait_type}:</label>
                                      <label>{value.value}</label>
                                    </div>
                                  )
                                  )
                                }

                                <div>
                                  <label>NFT Claimable Share :</label>
                                  <label>{list.shareValue>0?convert( list.shareValue/ 1e18):0} ETH</label>
                                </div>

                              </div>
                              {/* {alert(index)} */}
                              {loader == index ?
                                <button className="secondary_btn mt-3" disabled onClick={() => { ClaimShares([Number(list.name)], index, list.shareValue) }}>
                                  <i class="fa fa-refresh fa-spin" style={{ fontSize: "24px" }} ></i>  Claim NFT Share
                                </button> :
                                <button className="secondary_btn mt-3" onClick={() => { ClaimShares([Number(list.name)], index, list.shareValue) }}>
                                  Claim NFT Share
                                </button>
                              }
                            </div>
                          </div>
                        </>
                      ))
                    }



                    {/* <div className="owned_nft_list">
                    <div className="form-check">
                      <input
                        className="form-check-input form-checkbox-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </div>
                    <div className="nft_img_wrap">
                      <img
                        src={require("../assets/images/nft_02.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </div>
                    <div className="nft_description">
                      <h2>Ship storm with burning flame</h2>
                      <h3>Token ID : 210</h3>
                      <div className="nft_details">
                        <div>
                          <label>Color :</label>
                          <label>Red</label>
                        </div>
                        <div>
                          <label>Token Standard :</label>
                          <label>ERC-721</label>
                        </div>
                        <div>
                          <label>Type :</label>
                          <label>Animated</label>
                        </div>
                        <div>
                          <label>Chain :</label>
                          <label>Ethereum</label>
                        </div>
                        <div>
                          <label>Category :</label>
                          <label>Fighter</label>
                        </div>
                        <div>
                          <label>NFT Claimable Share :</label>
                          <label>10 ETH</label>
                        </div>
                      </div>
                      <button className="secondary_btn mt-3">
                        Claim NFT Share
                      </button>
                    </div>
                  </div>

                  <div className="owned_nft_list">
                    <div className="form-check">
                      <input
                        className="form-check-input form-checkbox-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </div>
                    <div className="nft_img_wrap">
                      <img
                        src={require("../assets/images/nft_03.png")}
                        className="img-fluid"
                        alt="Copy"
                      />
                    </div>
                    <div className="nft_description">
                      <h2>Digital blue painting</h2>
                      <h3>Token ID : 132</h3>
                      <div className="nft_details">
                        <div>
                          <label>Color :</label>
                          <label>Red</label>
                        </div>
                        <div>
                          <label>Token Standard :</label>
                          <label>ERC-721</label>
                        </div>
                        <div>
                          <label>Type :</label>
                          <label>Animated</label>
                        </div>
                        <div>
                          <label>Chain :</label>
                          <label>Ethereum</label>
                        </div>
                        <div>
                          <label>Category :</label>
                          <label>Fighter</label>
                        </div>
                        <div>
                          <label>NFT Claimable Share :</label>
                          <label>10 ETH</label>
                        </div>
                      </div>
                      <button className="secondary_btn mt-3">
                        Claim NFT Share
                      </button>
                    </div>
                  </div>
                  <button className="primary_btn mt-3">
                    Claim the Share of Selected NFTs
                  </button> */}
                  </div>
                </div>
              </div>
              {
                ClaimbedHistory && ClaimbedHistory.length > 0 ? <div>
                  <h2 className="inner_heading">Claimable Shares History</h2>
                  <div className="approved_market_div">
                    <div className="table-responsive">
                      <table className="approved_market_table table">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Token Id </th>
                            <th>Claim </th>
                            <th>Claimed Amount</th>
                            <th>Transaction Hash</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            ClaimbedHistory && ClaimbedHistory.length > 0 && ClaimbedHistory.map((list, index) => {
                              return (
                                <tr key={list._id}>
                                  <td>{index + 1}</td>
                                  <td>{
                                    list.nooftoken.length > 1 ? list.nooftoken.toString() : list.nooftoken[0]
                                  }</td>
                                  <td>{list.nooftoken.length > 1 ? 'Multipe' : 'single'}</td>
                                  <td>

                                    {list.ClaimedAmount>0?convert(list.ClaimedAmount / 1e18):0}

                                  </td>
                                  <td>
                                    <a href={`https://sepolia.etherscan.io/tx/${list.transactionHash}`} target="_blank">
                                      {Converter(list.transactionHash)}
                                    </a>
                                  </td>
                                  <td>
                                    {
                                      moment(list.updatedAt).format("MMMM,Do YYYY, hh:mm A")
                                    }
                                  </td>

                                </tr>
                              )
                            }
                            )
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> : <></>
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
