import React from "react";
import Web3 from "web3";
import { contractABI } from "../contract/contractABI";
import { ClaimedShare, nftMint } from "../actions/user";
import toastAlert from "./ToastAlert";
import details from '../lib/network'
import { toast } from "react-toastify";
export const MintNft = async (value, ContractAddress, nftprice, setMintLoader,totalminted,totalsupply) => {
  console.log(value, 'Suriya001',totalminted);
  const currectAddress = localStorage.getItem("address");
  setMintLoader(true)
  try {
    if (!window && !window.ethereum) {
      setMintLoader(false)
      return alert("please connect metamask externally");
    } else {
      const { ethereum } = window;
      const web3 = new Web3(ethereum ||'https://rpc.sepolia.org');

      if (
        localStorage.getItem("wallet_type") &&
        localStorage.getItem("wallet_type") === "metamask"
      ) {
        const contract = new web3.eth.Contract(contractABI, ContractAddress);
        setMintLoader(true)
        console.log(contract, "concon");
        const nonce = await web3.eth.getTransactionCount(currectAddress);
        const gasprice = await web3.eth.getGasPrice();

        const amount = Number(value) * nftprice;
        console.log(amount, 'amount');
        // const val = web3.utils.toWei(amount.toString(), "ether");
        // console.log(val, "vallll");
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        const amt = amount

        console.log(value,'sak');
        
        //value convert 
        const nft = value * 1e18
        var var1 = nft.toString()
        var1 = await convert(var1)


        //amount convert
        const amt1 = amt * 1e18
        var var2 = amt1
        var2 = await convert(var2)


        console.log(amt, nft, var1,var2, "suryaa",value,typeof value);

        const transaction = await contract.methods.MintNFT(value).send({
          value: var2.toString()
          , from: currectAddress
        })
        // const transaction=await contract.deposit(420, { value: 10000000000000000000, from: web3.eth.accounts[0]})
        console.log(transaction, 'transa');

        const payload = {
          transactionHash: transaction.transactionHash,
          ContractAddress: ContractAddress,
          currentAddress: currectAddress,
          tokenId:'',
          NoofToken: value,
          amount: var2,
          totalMinted:totalminted,
          totalSupply:totalsupply
        }
        console.log(payload, 'paayyyyyyload');
        const result = await nftMint(payload)
        toastAlert('success', `${result.message}`)
        setMintLoader(false)
        setTimeout(() => {
          window.location.reload('')
        }, 2000);

      }
    }
  } catch (error) {
    setMintLoader(false)
    console.log(error);
  }
};


export function convert(n) {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);
  return +pow < 0
    ? sign +
    "0." +
    "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
    lead +
    decimal
    : sign +
    lead +
    (+pow >= decimal.length
      ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
      : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
}


export const ClaimbaleShares = async (tokenId, ContractAddress, setLoader, setAllShareLoader) => {
  // setLoader(true)
  // setAllShareLoader(true)
  if (
    localStorage.getItem("wallet_type") &&
    localStorage.getItem("wallet_type") === "metamask"
  ) {
    if (!window && !window.ethereum) {
      setLoader(undefined)
      setAllShareLoader(false)

      return alert("please install metamask");
    } else {
      try {
        const { ethereum } = window
        const wallet_type = await localStorage.getItem('network')
        // setAllShareLoader(true)

        console.log(wallet_type);
        const selectedNetwork = await details.find((list) => (String(list.name) === String(wallet_type)) ? list : '')
        console.log(selectedNetwork, 'selectedNetwork');

        const web3 = new Web3(ethereum || selectedNetwork.rpcUrls);
        let currentAddress = localStorage.getItem("address");
        const contract = new web3.eth.Contract(contractABI, ContractAddress);
        console.log(contract, "contarctllll", currentAddress);
        const tokenWithShare=[]
        const tokenWithoutShare=[]
        var checkShare=0
        // tokenId.map(async(val)=>{
        //   console.log(typeof val);
        //    checkShare = await contract.methods.CheckShare([val]).call()
        //    console.log(checkShare);
        //   if(checkShare>0){
        //     tokenWithShare.push(val)
        //   }else {
        //     tokenWithoutShare.push(val)
        //   }
        // })
        for(let i=0;i<tokenId.length;i++){
          const val=tokenId[i]
          checkShare = await contract.methods.CheckShare([val]).call()
             console.log(checkShare);
            if(checkShare>0){
              tokenWithShare.push(val)
            }else {
              tokenWithoutShare.push(val)
            }
        }
       const checkShares = await contract.methods.CheckShare([tokenWithShare]).call()
       const shareValue=await checkShares*tokenWithShare.length
        
        console.log(checkShares,checkShares*tokenWithShare.length,shareValue/1e18,'tokenshare');
        console.log(tokenId);
        console.log(tokenWithShare);
        console.log(tokenWithoutShare);
        const ClaimShares = await contract.methods.ClaimShare(tokenId).send({ from: currentAddress})
        console.log(ClaimShares);
        const Payload = {
          contractAddress: ContractAddress,
          currentAddress: currentAddress,
          NoofToken: tokenWithShare,
          shareAmount: shareValue,
          transactionHash: ClaimShares.transactionHash
        }
        const result = await ClaimedShare(Payload)
        console.log(result.message);
        toast.success(result.message)
        setTimeout(() => {
          window.location.reload('')
        }, 2000);
      } catch (error) {
        setLoader(undefined)
        console.log(error);
        setAllShareLoader(false)
      }
    }
  }
}