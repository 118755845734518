

let Front_URL = "";
let Back_URL = "";
let v1Url = "";
let limit = "12";
let limitMax = "3000";
let decimalvalues = 1000000000000000000;
let toFixed = 8;
let Symbol= '';
let IPFS_IMG = "https://ipfs.io/ipfs";
let Contract_Address='';
let NetworkId=11155111;
let rpcUrl=''
const NODE_ENV = "production"

if (NODE_ENV === "production") {
  Front_URL = "https://7thsea-main-page.pages.dev/";
  Back_URL = "https://7thseaapi.wearedev.team/";
  v1Url = "";
  IPFS_IMG = "https://ipfs.io/ipfs";
  Contract_Address='0x9d5fdf15fB719B25d9eBD56Fe0715A0A7d09c47E';
  rpcUrl= "https://bsc-dataseed1.binance.org";
  Symbol= 'tBNB';
} else {
  Front_URL = "http://localhost:3000/";
  Back_URL = "http://localhost:3040/";
  v1Url = "http://localhost:3040/api";
  IPFS_IMG = "https://ipfs.io/ipfs";
  Contract_Address='0x9d5fdf15fB719B25d9eBD56Fe0715A0A7d09c47E';
  rpcUrl="https://bsc-dataseed1.binance.org";
  Symbol= 'tBNB';
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  limit: limit,
  limitMax: limitMax,
  IPFS_IMG: IPFS_IMG,
  Contract_Address:Contract_Address,
  NetworkId:NetworkId,
  rpcUrl:rpcUrl,
  Symbol:Symbol
};

export default key;
