let key = {};
if (process.env.NODE_ENV === "production") {
  key = 
   [ {
      name: "binance",
      chainname: "BINANCE TESTNET",
      chainsymbol: "BNB",
      tokensymbol: "WBNB",
      rpcUrls: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      blockExplorerUrls: "https://testnet.bscscan.com/",
      icon: "/assets/images/binanceicon.png",
      networkversion: 97,
      chainid: 97,
      networkdecimal: 18,
      tradecontract: "0x4F598Bf15fE2973D2C7ae84515ec3803A2675142",
      tokencontract: "0x673d89777642b27eA7F1173738B772f8a823e683",
      singleContract: "0x3d3754f55c6413a817b7c3c2370062001108b427",
      multipleContract: "0xfAB8FBaaE07BB7bB9B5a422ff9b4C9297F4BCF67",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "",
    },{
      name: "ethereum",
      chainname: "Goerli Test Network",
      chainsymbol: "ETH",
      tokensymbol: "WETH",
      rpcUrls: "https://goerli.infura.io/v3/",
      blockExplorerUrls: "https://goerli.etherscan.io/",
      icon: "/assets/images/etheriumicon.png",
      networkversion: 5,
      chainid: 5,
      networkdecimal: 18,
      tradecontract: "0x11F7D7f017B46703DDd4e1fF32D99120F5E3960E",
      tokencontract: "0x36ed7A4fd0101FB9F16a94070744FFc8b4e92364",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "3a495f15c007471e8fcff8cbfd22ae21",
    },
    {
      name: "sepolia",
      chainname: "sepolia Test Network",
      chainsymbol: "ETH",
      tokensymbol: "WETH",
      rpcUrls: "https://rpc.sepolia.org",
      blockExplorerUrls: "https://goerli.etherscan.io/",
      icon: "/assets/images/etheriumicon.png",
      networkversion: 11155111,
      chainid: 11155111,
      networkdecimal: 18,
      tradecontract: "0x11F7D7f017B46703DDd4e1fF32D99120F5E3960E",
      tokencontract: "0x36ed7A4fd0101FB9F16a94070744FFc8b4e92364",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "3a495f15c007471e8fcff8cbfd22ae21",
    }
]
} else {
  key = 
   [ {
      name: "binance",
      chainname: "BINANCE TESTNET",
      chainsymbol: "BNB",
      tokensymbol: "WBNB",
      rpcUrls: "https://bsc-testnet.publicnode.com",
      blockExplorerUrls: "https://goerli.etherscan.io/",
      icon: "/assets/images/binanceicon.png",
      networkversion: 97,
      chainid: 97,
      networkdecimal: 18,
      tradecontract: "0x26636Aee97758226271383e7911b64F13FeFA058", // "0x4F598Bf15fE2973D2C7ae84515ec3803A2675142",
      tokencontract: "0x673d89777642b27eA7F1173738B772f8a823e683",
      singleContract: "0x3d3754f55c6413a817b7c3c2370062001108b427",
      multipleContract: "0xfAB8FBaaE07BB7bB9B5a422ff9b4C9297F4BCF67",
      defaultCollectionSingle: "0xaf6643fa4952e9e7e2a8d2e99be1091c9e5f86a2",
      defaultCollectionMultiple: "0x418771520cBEC752dDDe6e1254263619D7CA2119",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "",
    },{
      name: "ethereum",
      chainname: "Goerli Test Network",
      chainsymbol: "ETH",
      tokensymbol: "WETH",
      rpcUrls: "https://goerli.infura.io/v3/",
      blockExplorerUrls: "https://goerli.etherscan.io/",
      icon: "/assets/images/etheriumicon.png",
      networkversion: 5,
      chainid: 5,
      networkdecimal: 18,
      tradecontract: "0x11F7D7f017B46703DDd4e1fF32D99120F5E3960E",
      tokencontract: "0x36ed7A4fd0101FB9F16a94070744FFc8b4e92364",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "3a495f15c007471e8fcff8cbfd22ae21",
    },{
      name: "sepolia",
      chainname: "sepolia Test Network",
      chainsymbol: "ETH",
      tokensymbol: "WETH",
      rpcUrls: "https://rpc.sepolia.org",
      blockExplorerUrls: "https://goerli.etherscan.io/",
      icon: "/assets/images/etheriumicon.png",
      networkversion: 11155111,
      chainid: 11155111,
      networkdecimal: 18,
      tradecontract: "0x11F7D7f017B46703DDd4e1fF32D99120F5E3960E",
      tokencontract: "0x36ed7A4fd0101FB9F16a94070744FFc8b4e92364",
      formatic_API: "pk_test_BB7E74A7DA43B8E9",
      Appname: "NFTWEALWIN",
      InfuraKey: "3a495f15c007471e8fcff8cbfd22ae21",
    }
]
}

export default key;
