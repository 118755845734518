import React from "react";
import Web3 from "web3";
import { contractABI } from "../contract/contractABI";
import details from '../lib/network'
import axios from "axios";
import { UpdateToken, getTokenData } from "../actions/user";
import config from '../lib/config'


export const ReadUserContract = async (
  result,
  setTotalMint,
  setUserHold,
  setUserMint,
  setWhileList,
  setWhiteListAddr,
  setFreeMint,
  setCurrentETHbalance,
  setBaseURi,
  setTokenJson,
  setTotalSupply,
  setCheckShare,
  tokenid,
  setTotalShareValue,
  setSingleShare
) => {
  console.log(result, "ress");
  let totalSupply;
  let TokenArray = []
  let claimpableShares = []
  try {
    console.log('eeeeeeeeeeeeeeerrrrrrrrrrrrr');
    if (
      localStorage.getItem("wallet_type") &&
      localStorage.getItem("wallet_type") === "metamask"
      ) {
      if (!window && !window.ethereum) {
        return alert("please install metamask");
      } else {
        // const wallet_type = await localStorage.getItem('network')
        // console.log(wallet_type);
        // const wallet_type='binance'
        // const selectedNetwork = await details.find((list) => (Number(list.chainid) == Number(config.NetworkId)) ? list : '')
        
        // console.log(selectedNetwork, 'selectedNetwork');
        const web3 = new Web3(window.ethereum||'https://rpc.sepolia.org');
        // console.log(web3,'selectedNetwork');
        let currentAddress = localStorage.getItem("address");
        const contract = new web3.eth.Contract(contractABI, result);
        console.log(contract, "contarctllll");
        // UserMint
        const usercount = await contract.methods
          .userCount(currentAddress)
          .call()
          .then((value) => {

            setUserMint(value);
            console.log(value, 'usercount');
          })
          .catch((err) => console.log(err));
        console.log(usercount, "usercount");
        //userHold
        const userhold = await contract.methods
          .userHold(currentAddress)
          .call()
          .then((value) => {
            setUserHold(value);
            console.log(value, 'userHold');
          })
          .catch((err) => console.log(err));

        //TotalMinted
        const totalmint = await contract.methods
          .totalminted()
          .call()
          .then((value) => {
            setTotalMint(value);
            console.log(value);
          })
          .catch((err) => console.log(err));
        console.log(totalmint, "totalmint");
        //WhiteListed
        const isWhitelist = await contract.methods.whitelist().call()
          .then((value) => {
            console.log(value, 'isWhiteList');
            setWhileList(value)
          }).catch((err) => {
            console.log(err);
          })
          const isWhitelistAddre = await contract.methods.whitlistedAddress(currentAddress).call()
          .then((value) => {
            console.log(value, 'isWhiteListAddr');
            setWhiteListAddr(value)
          }).catch((err) => {
            console.log(err);
          })
        //
        const freeMinting = await contract.methods.FreeMint().call()
          .then((value) => {
            setFreeMint(value)
          }).catch((err) => {
            console.log(err)
          })
          //current ETH Balance
        const CurrentEth = await contract.methods.CurrentETHbalance().call()
          .then((value) => {
            console.log(value, 'CurrentEth');
            setCurrentETHbalance(value)
          }).catch((err) => {
            console.log(err);
          })
          //baseUri
        const baseURI = await contract.methods.baseURI().call()
          .then(async (value) => {
            console.log(value, 'baseUri');
            setBaseURi(value)


          }).catch(err => {
            console.log(err);
          })
          //totalSupply
        const Supply = await contract.methods.totalSupply().call()
          .then((value) => {
            totalSupply = value
            console.log(value, 'totalSupplytotalSupplytotalSupplytotalSupplytotalSupply');
            setTotalSupply(value)
          }).catch((err) => {
            console.log(err);
          })
          console.log(tokenid,'tokenid1'); // user Minted  TOken ID 


          const resss=await contract.methods.getUserToken(currentAddress).call()
          console.log(resss,'userTokens', tokenid);
          //check Total Share Value of the user
          const totalShareValue=await contract.methods.CheckShare(resss).call()
          .then(async(value)=>{
            setTotalShareValue(value)
          }).catch((err)=>{
            console.log(err);
          })
          //check single chare value
          // const values = await contract.methods.CheckShare([1]).call()
          // .then(async(value)=>{
          //   setSingleShare(value)
          // }).catch((err)=>{
          //   console.log(err);
          // })

          // gety userToken

         

           Array.prototype.diff = function(a) {
            return this.filter(function(i) {return a.indexOf(i) < 0;});
        };


        const dif1 = resss.diff( tokenid.toString() );  
console.log(dif1,'userTokens'); 

          //UserMint Token

          // const oldOwnerToken=[]
          // const newOwnerToken=[]
          // let newOwnerAddress=''
          // // for(let i=0;i<tokenid.length;i++){
          // //   const TokenOwner=await contract.methods.ownerOf(tokenid[i]).call()
          // //   if(currentAddress==TokenOwner){
          // //     oldOwnerToken.push(tokenid[i])
          // //   }else{
          // //     newOwnerAddress=TokenOwner
          // //     newOwnerToken.push(tokenid[i])
          // //   }
          // // }
          
          // console.log(oldOwnerToken,currentAddress,newOwnerToken,newOwnerAddress,'Coooooooooooooooooooo');



        if(resss.length>0){
          resss.forEach(async(e)=>{
            const Token = await contract.methods.tokenURI(e).call()
            try {
  
              await axios({ url: `${Token}`, method: 'get' })
  
                .then(async (result) => {
  
                  const text = JSON.parse(JSON.stringify(result.data))
                  console.log(text, 'rrrrrrrrrrrrrrrrrrrrrrrrr');
                  //individual share value
                  const values = await contract.methods.CheckShare([e]).call()
                  TokenArray.push({ ...text,shareValue:values})
                  
             
                }).catch((err) => {
                  console.log(err);
                })
             
            }
            catch (err) {
              console.log(err, 'err');
            }
            console.log(TokenArray,'tokenid');
            const variable=TokenArray.sort((a,b)=>Number(a.name) > Number(b.name) ? 1 : -1)
            console.log(variable,'tokenid');
            setTokenJson([...variable])
          })
        }else{
          return 
        }
        
        if(dif1&&dif1.length>0){
          const payload={
            contractaddress:result,
            newownertoken:dif1,
            newOwnerAddress:currentAddress,      
          }

          const responce=await UpdateToken(payload)
          // console.log();
        }
          
       
        // for (var i = 0; i < j; i++) {
       
        // }
        // const responce = await axios({ url: 'https://ipfs.io/ipfs/QmNxkWRA9qQAcBRdbATTqDPLw5pk7UPYZEJSpvNzergiQ6/3.json', method: 'get' })
        // console.log(responce.data, 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr============');

      }

    }
  } catch (error) {
    console.log(error);
  }
};

