import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import {
  FilterCollectionList,
  getAllCollectionList,
  getFaqCollection,
  searchCollections,
} from "../actions/user.js";
import config from "../lib/config";
import { Link } from "react-router-dom";
import { Converter } from "../script/converter.js";
import toastAlert from "../script/ToastAlert.js";

//
import DataTable from "react-data-table-component";
// import { Table } from "antd";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [collectionList, setCollectionList] = useState([]);
  const [faqlist, setFaqList] = useState([]);
  const [search, setSearch] = useState({
    search: "",
    filter: ".",
  });
  const scrollref=useRef()
  const [filter, setFilter] = useState("");
  const [pageNumber,setPageNumber]=useState(1)
  const [totalCollection,setTotalCollection]=useState()
  const [totalpage,setTotalPage]=useState()
  const [pageLimit,setpageLimit]=useState(10)
  // const pageLimit=10
  const ssno=(pageNumber-1)*pageLimit
  useEffect(() => {
    AOS.init();
    // loadscript();
  }, []);

  // function loadscript() {
  //   $(document).ready(function () {      
  //     $(".accordion-button").click(function () {        
  //       $(".accordion-button").on();
  //       $(".accordion-item").removeClass("active");
  //       $(this).closest(".accordion-item").addClass("active");
  //     });
  //   });
  // }
  

  useEffect(() => {
    getloader();
    //  searchHanlder();
  }, [search.filter, search.search]);

  const getloader = async () => {
    await getCollections();
    await FaqCollection();
  };

  useEffect(()=>{
    getCollections()
  },[pageNumber,pageLimit])

  const FaqCollection = async () => {
    const result = await getFaqCollection();
    if (result.length > 0) {
      setFaqList([...result]);
    }
  }

  const getCollections = async () => {
    const collection = await getAllCollectionList(search,pageNumber,pageLimit);
    console.log(collection, 'rez');

    if (collection.result.length > 0) {
      // console.log(collection.result,"run");
      collection.result.map((ele, i) => {
        ele.SSNo = i + 1+ssno
      })
      setCollectionList([...collection.result]);
      setTotalPage(collection.totalpages)
      setTotalCollection(collection.length)
    }
  };
  const searchHanlder = async () => {
    const result = await searchCollections(search);
    setCollectionList([...result]);
  };
  const searchChangeHanlder = async (e) => {
    const { id, value } = e.target;
    setSearch({ ...search, [id]: value });
    console.log(search);
  };
  console.log(collectionList, 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
  const handleChage = (pagenumber) => {
    if (scrollref.current) {
      const tableContainer = scrollref.current;
      const tableHeading = tableContainer.querySelector('.table-heading');
      setPageNumber(pagenumber)
      console.log(tableHeading);
      if (tableHeading) {
        console.log(pagenumber, 'pageNumber');
        
        // tableContainer.scrollTop = tableHeading.offsetTop;
        tableHeading.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // window.location.href = "/7thsea/#tblTop"
  }


  const handleRowPerPageChange=(limit)=>{
    
    if (scrollref.current) {
      const tableContainer = scrollref.current;
      const tableHeading = tableContainer.querySelector('.table-heading');
      setpageLimit(limit)
      console.log(tableHeading);
      if (tableHeading) {
        console.log(limit, 'pageNumber');
        
        // tableContainer.scrollTop = tableHeading.offsetTop;
        tableHeading.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  const columns = [{



    name: "S.No",
    // dataIndex: "SSno",
    // title: "S.NO",
    width: '100px',
    cell: (value) => {
      // {console.log(text,value,index,'rrrh')}
      return (
        <div>{value.SSNo}</div>
      )
    }
  }, {
    name: "Collection",
    // dataIndex: "totalsupply",
    // title: "Collection",
    width: "300px",

    cell: (value, index) => {
      return (
        <div> <img
          src={`${value.logourl}`}
          className="img-fluid table_collection_img"
          alt="Collection"
        />
          <span>{value.name}</span></div>
      )
    }
  }, {
    name: "Mint Status",
    // dataIndex: "totalsupply",
    // title: "Mint Status",
    width: "150px",

    cell: (value, index) => {
      return (
        <div>  {value.mintopen ? (
          <span className="text_green">Open</span>
        ) : (
          <span className="text_red">Close</span>
        )}</div>
      )
    }
  }, {
    name: "Collection Address",
    // dataIndex: "totalsupply",
    // title: "EtherScan Link",
    width: "270px",

    cell: (value, index) => {
      return (
        <div><a href={`https://sepolia.etherscan.io/address/${value.ContractAddress}`} target="_blank" style={{ color: "white" }}>
          {Converter(value.ContractAddress)}
        </a></div>
      )
    }
  }, {
    name: "Collection Details",
    // dataIndex: "totalsupply",
    // title: "Collection Details",
    width: "170px",

    cell: (value, index) => {
      return (
        <div> {
          localStorage.getItem('7thSeaLogin') == 'yes' && localStorage.getItem('address') ? <Link to={`collection-details/${value._id}`} style={{ color: "white" }}>
            View Details
          </Link> : <Link onClick={() => toastAlert('error', 'Connect wallet')} style={{ color: "white" }}>
            View Details
          </Link>
        } </div>
      )
    }
  }]
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container-fluid">
          <div data-aos="fade-up" data-aos-duration="3000">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              {/* <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div> */}
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={require("../assets/images/banner_slider_01.png")}
                    className="img-fluid d-block w-100"
                    alt="Icon"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../assets/images/banner_slider_02.png")}
                    className="img-fluid d-block w-100"
                    alt="Icon"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../assets/images/banner_slider_03.png")}
                    className="img-fluid d-block w-100"
                    alt="Icon"
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Collections Table Section */}
        <section className="collection_section" >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Collections
                </h2>
                <div
                  className="collections_table_sorting"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for Collections"
                      style={{ color: "white" }}
                      aria-label="Recipient's username"
                      id="search"
                      onChange={searchChangeHanlder}
                      aria-describedby="button-addon2"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                    >
                      <i className="bi bi-search"></i>
                    </button>
                  </div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id={"filter"}
                    onClick={searchChangeHanlder}
                  >
                    <option value=".">Show all</option>
                    <option value="true">Mint Open</option>
                    <option value="false">Mint Close</option>
                  </select>
                </div>
                <div
                ref={scrollref}
                  className="collections_table_div "
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  // style={{ overflowY: 'auto' }}
                >
                  <div className="table-responsive table-heading">
                    <table className="table  collections_table">
                      {
                        collectionList && collectionList.length > 0 ?

                          <DataTable

                            columns={columns}
                            // pagination={{ color:'white' }}
                            data={collectionList}
                          //   onPageChange={handleChage}
                          //  onRowsPerPageChange={handleChage}
                          // highlightOnHover
                            onChangePage={handleChage}
                            pagination={true}
                            paginationServer
                            paginationTotalRows={totalCollection}
                            paginationPerPage={pageLimit}
                            onChangeRowsPerPage={handleRowPerPageChange}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          /> : ''
                      }
                      {/* <thead>
                        <tr>
                          <th>#</th>
                          <th>Collection</th>
                          <th>Mint Status</th>
                          <th>Etherscan Link</th>
                          <th>Collection Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collectionList && collectionList.length > 0 ? (
                          collectionList.map((list, index) => {
                            return (
                              <tr key={list._id}>
                                <td>{index + 1}</td>
                                <td>
                                  <img
                                    src={`${list.logourl}`}
                                    className="img-fluid table_collection_img"
                                    alt="Collection"
                                  />
                                  <span>{list.name}</span>
                                </td>
                                <td>
                                  {list.mintopen ? (
                                    <span className="text_green">Open</span>
                                  ) : (
                                    <span className="text_red">Close</span>
                                  )}
                                </td>
                                <td>
                                  <a href="#" target="_blank">
                                    {Converter(list.ContractAddress)}
                                  </a>
                                </td>
                                <td>
                                  {
                                    localStorage.getItem('address') ? <Link to={`collection-details/${list._id}`}>
                                      View Details
                                    </Link> : <Link onClick={() => toastAlert('error', 'Connect wallet')}>
                                      View Details
                                    </Link>
                                  }

                                  {/* <span >View Details</span> */}
                      {/* </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>No data Found</tr>
                        )}  */}

                      {/*  */}
                      {/* <tr>
                          <td>2</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_02.png")}
                              className="img-fluid table_collection_img"
                              alt="Collection"
                            />
                            <span>Tie Dye Ninja</span>
                          </td>
                          <td>
                            <span className="text_red">Close</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_03.png")}
                              className="img-fluid table_collection_img"
                              alt="Collection"
                            />
                            <span>Tie Dye Ninja</span>
                          </td>
                          <td>
                            <span className="text_red">Close</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_04.png")}
                              className="img-fluid table_collection_img"
                              alt="Collection"
                            />
                            <span>Galaxy fighter</span>
                          </td>
                          <td>
                            <span className="text_green">Open</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_05.png")}
                              className="img-fluid table_collection_img"
                              alt="Collection"
                            />
                            <span>Tie Dye Ninja</span>
                          </td>
                          <td>
                            <span className="text_green">Open</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_06.png")}
                              className="img-fluid table_collection_img"
                              alt="Collection"
                            />
                            <span>Tie Dye Ninja</span>
                          </td>
                          <td>
                            <span className="text_red">Close</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            <img
                              src={require("../assets/images/collection_07.png")}
                              className="img-fluid table_collection_img"
                              alt="Benefits"
                            />
                            <span>Galaxy fighter</span>
                          </td>
                          <td>
                            <span className="text_green">Open</span>
                          </td>
                          <td>
                            <a href="#" target="_blank">
                              5xahpo25abg.....638495
                            </a>
                          </td>
                          <td>
                            <span>View Details</span>
                          </td>
                        </tr>  */}
                      {/* </tbody>  */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <h2
                  className="main_title text-center d_d_none"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Treasures of the Seas
                </h2>
                <div className="benefits_panel">
                  <div
                    className="benefits_left"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                  >
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                    </ul>
                  </div>
                  <div
                    className="benefits_middle"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="300"
                  >
                    <img
                      src={require("../assets/images/dolphin_desktop.png")}
                      className="img-fluid"
                      alt="Benefits"
                    />
                  </div>
                  <div
                    className="benefits_right"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="500"
                  >
                    <ul>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        consectetur
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Roadmap Section */}
        <section className="roadmap_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 text-center">
                <div className="roadmap_list">
                  <div
                    className="roadmap_list_single"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="300"
                  >
                    <h2>Phase 01</h2>
                    <p>
                      Lorem ipsum dolor sit amet, adipiscing elit consectetur{" "}
                    </p>
                  </div>
                  <div
                    className="roadmap_list_single"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="600"
                  >
                    <h2>Phase 02</h2>
                    <p>
                      Lorem ipsum dolor sit amet, adipiscing elit consectetur{" "}
                    </p>
                  </div>
                  <div
                    className="roadmap_list_single"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="900"
                  >
                    <h2>Phase 03</h2>
                    <p>
                      Lorem ipsum dolor sit amet, adipiscing elit consectetur{" "}
                    </p>
                  </div>
                  <div
                    className="roadmap_list_single"
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    data-aos-delay="1200"
                  >
                    <h2>Phase 04</h2>
                    <p>
                      Lorem ipsum dolor sit amet, adipiscing elit consectetur{" "}
                    </p>
                  </div>
                </div>
                <img
                  src={require("../assets/images/roadmap_ship.png")}
                  className="img-fluid roadmap_shipimg"
                  alt="Roadmap"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                />
                <p
                  className="inner_para"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  data-aos-delay="1500"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo
                  eiusmod tempor incididunt ut labore consectetur adipiscing
                  elit, seddo eiusmod tempor incididunt ut labore
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-8 m-auto">
                <h2
                  className="main_title text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Faq
                </h2>
                <p
                  className="inner_para text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="faq_accordion"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="accordion" id="accordionExample">
                    {faqlist &&
                      faqlist.map((list) => {
                        return (
                          <div className="accordion-item" key={list._id}>
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${list._id}`}
                                aria-expanded="false"
                                aria-controls={`collapse${list._id}`}
                              >
                                {list.question}
                              </button>
                            </h2>
                            <div
                              id={`collapse${list._id}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                {list.answers}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Lorem ipsum dolor sit amet?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Lorem ipsum dolor sit amet?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the
                          though the transition does limit overflow.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Lorem ipsum dolor sit amet?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          It is hidden by default, until the collapse plugin
                          adds the appropriate classes that we use to style each
                          element. These classes control the overall appearance,
                          as well as the showing and hiding via CSS transitions.
                          You can modify any of this with custom CSS or
                          overriding our default variables. It's also worth
                          noting that just about any HTML can go within the
                          though the transition does limit overflow.
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
